

const INTERACTION_DISTANCE = Number(process.env.REACT_APP_INTERACTION_DISTANCE);



function latToDist(lat) {
  return (lat / 180) * (40008 / 2);
}

function lonToDist(lon, lat) {
  return (lon / 360) * 40008 * Math.cos((lat * Math.PI) / 180);
}

export function isAtCheckpoint(p1, p2) {
  const dLng = p2.lng - p1.lng;
  const dLat = p2.lat - p1.lat;

  /*
  const dN = (dLat / 180) * (40008 / 2);
  const dE = (dLng / 360) * 40008 * Math.cos((p1.lat * Math.PI) / 180);
  */
  const dN = latToDist(dLat);
  const dE = lonToDist(dLng, p1.lat);

  const dist = (dN ** 2 + dE ** 2) ** 0.5;
  if (dist < INTERACTION_DISTANCE) {
    return true;
  }
  return false;
}

export function calculateMissionCenter(cptArr) {
  let sumLat = 0;
  let sumLon = 0;

  for (let i = 0; i < cptArr.length; i++) {
    const loc = cptArr[i].location.coordinates;
    const lon = loc[0];
    const lat = loc[1];
    sumLon += lon;
    sumLat += lat;
  }

  const lon = sumLon / cptArr.length;
  const lat = sumLat / cptArr.length;

  return [lon, lat];
}

