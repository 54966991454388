import { useEffect } from 'react'
import { useDispatch } from 'react-redux';

import { setMap } from '../redux/mapSlice';
import H2 from './reusable/H2';

function Landing() {
  const dispatch = useDispatch();

  // Effect for setting the map
  useEffect(() => {
    dispatch(setMap({active: false, mapType: null}));
  }, [dispatch]);

  return (
    <div className="h-full w-full text-left px-[1em]">

      <p className="">Create missions, hit checkpoints, learn your local area and explore new ones!</p>
      <p className="">Find your own way between checkpoints, go fast, go slow, travelling by any means.</p>
      <p className="mb-[1.5em]">I can't guarantee you will like the destination, but hopefully you will enjoy the journey!</p>

      <div className="text-center" >
        <H2 text="No Account" />
      </div>
      <ul className="mb-[1.5em] list-disc list-inside text-left w-fit">
        <li>Create & attempt your own missions</li>
        <li>Attempt missions saved by other users</li>
      </ul>

      <div className="text-center" >
        <H2 text="With Account" />
      </div>
      <ul className="list-disc list-inside text-left w-fit">
        <li>Save unlimited missions & attempts</li>
        <li>Contribute your own checkpoints to the public database</li>
      </ul>

    </div>
  );
}

export default Landing;
