import React, { useState, useEffect, useReducer, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { TextInput, MultiChoiceInput } from '../reusable/Input';
import { YesButton, NoButton } from '../reusable/Buttons';
import Api from '../../Api';
import { initNewCpt, newCptReducer, initCptList, cptListReducer } from '../../state';
import { useGetAccessToken } from '../../hooks';
import {
  setMap,
  mapMarkerPosition,
  mapSelectCpts,
} from '../../redux/mapSlice';

const CreateCheckpoint = () => {
  const dispatch = useDispatch();

  const getAccessToken = useGetAccessToken();

  const [cptList, cptListDispatch] = useReducer(cptListReducer, initCptList);
  const [newCpt, newCptDispatch] = useReducer(newCptReducer, initNewCpt);
  const [allTags, setAllTags] = useState([]);




  // MAP STUFF /////////////////////////////////////////////////

  const markerPosition = useSelector((state) => state.map.markerPosition);

  // Effect for setting the map
  useEffect(() => {
    dispatch(setMap({active: true, mapType: 'select'}));
  }, [dispatch]);

  // Effect for setting display cpts
  useEffect(() => {
    dispatch(mapSelectCpts(cptList.cpts));
  }, [dispatch, cptList.cpts]);


  //////////////////////////////////////////////////////////////




  useEffect(() => {
    async function getCpts() {
      const token = await getAccessToken()
      const coords = [Number(markerPosition.lng), Number(markerPosition.lat)];
      const res = await Api.POST('/checkpoint/all-near', {token: token, data: {coords}});
      if (res?.status === 200) {
        let cpts = res?.data ?? [];
        for (let i = 0; i < cpts.length; i++) {
          cpts[i].visited = false;
          cpts[i].gmapsLoc = {
            lng: cpts[i].location.coordinates[0],
            lat: cpts[i].location.coordinates[1],
          };
        }
        cptListDispatch({type: 'set-cpts', payload: cpts});
      } else {
        cptListDispatch({type: 'set-error', payload: res?.status});
      }
    }
    if (cptList.status === 'requested') {
      cptListDispatch({type: 'set-status', payload: 'done'});
      getCpts();
    }
  }, [cptList, cptListDispatch, markerPosition, getAccessToken]);

  useEffect(() => {
    async function postData() {
      const token = await getAccessToken()
      const res = await Api.POST('/checkpoint', {token: token, data: newCpt.data});
      if (res.status !== 201) {
        newCptDispatch({ type: 'set-error', payload: res.status });
      }
      //setMarkerPosition(null);
      dispatch(mapMarkerPosition(null));
    }

    if (newCpt.status === 'requested') {
      postData();
      //newCptDispatch({ type: 'set-status', payload: 'received' });
      newCptDispatch({ type: 'set-status', payload: 'done' });
      newCptDispatch({ type: 'init-data', payload: null });
    }
  }, [newCpt, newCptDispatch, getAccessToken, dispatch]);


  useEffect(() => {
    async function getAllTags() {
      const res = await Api.GET('/tags');
      setAllTags(res?.data ?? [])
    }
    getAllTags();
  }, [setAllTags]);

  useEffect(() => {
    cptListDispatch({type: 'set-cpts', payload: null});
  }, [markerPosition]);





  const setName = useCallback((name) => {
    newCptDispatch({ type: 'set-name', payload: name });
  }, []);

  useEffect(() => {
    newCptDispatch({ type: 'set-coords', payload: markerPosition });
  }, [markerPosition]);

  const setTags = useCallback((tags) => {
    newCptDispatch({ type: 'set-tags', payload: tags });
  }, []);





  return (
    <div className="">

      <div className="relative">
        {cptList.cpts ? 
          <NoButton
            text='Clear search'
            onClick={() => cptListDispatch({type: 'set-cpts', payload: null})}
            isActive={markerPosition}
          />
          :
          <YesButton
            text='Search near'
            onClick={() => cptListDispatch({type: 'set-status', payload: 'requested'})}
            isActive={markerPosition}
          />
        }
      </div>


      <div className="bg-bgMain my-[2em] p-[1em]">

        <div className="">
          <TextInput
            value={newCpt.data.name}
            setValue={setName}
            label={'Name'}
          />
        </div>

        <div className="">
          <MultiChoiceInput options={allTags} chosen={newCpt.data.tags} setChosen={setTags} label='Add Tags' />
        </div>


        <div className="">
          <YesButton
            text='Create'
            onClick={() => newCptDispatch({type: 'set-status', payload: 'requested'})}
            isActive={markerPosition && newCpt.data.name !== ''}
          />
        </div>

      </div>

    </div>
  );
};

export default CreateCheckpoint;
