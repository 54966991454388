/* test */
import { createSlice } from '@reduxjs/toolkit';


function initState() {
  let initData = null;
  try {
    initData = JSON.parse(localStorage.getItem('attemptNoAccount'));
  } catch (err) {
    console.log(err);
  }
  const initAttempt = {
    data: initData, // data or null
    status: 'done', // 'requested', 'received', 'done'
    error: 0,
  };
  return initAttempt;
}


export const attemptNoAccountSlice = createSlice({
  name: 'attemptNoAccount',
  initialState: initState(),
  reducers: {
    atptStatus: (state, action) => {
      state.status = action.payload;
    },
    atptError: (state, action) => {
      state.error = action.payload;
    },
    atptData: (state, action) => {
      localStorage.setItem('attemptNoAccount', JSON.stringify(action.payload));
      state.data = action.payload;
    },
    atptClearData: (state) => {
      state.data = initState();
    },
    atptRemoveState: (state) => {
      localStorage.removeItem('attemptNoAccount');
      state.data = initState();
    },
  },
});

// Action creators are generated for each case reducer function
export const { atptStatus, atptError, atptData, atptClearData, atptRemoveState } = attemptNoAccountSlice.actions;

export default attemptNoAccountSlice.reducer;

