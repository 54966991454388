import { createSlice } from '@reduxjs/toolkit';


const mapping = {
  landing: 'About Alleycat',
  profile: 'Profile',

  'create-cpt': 'Create Checkpoint',
  'show-cpts': 'Show Checkpoints',

  'search-missions': 'Search Missions',
  'create-mission': 'Create Mission',
  'my-missions': 'My Missions',
  'my-attempts': 'My Attempts',
  attempt: 'Attempt',

  'create-mission-no-account': 'Create Mission',
  'search-missions-no-account': 'Search Missions',
  'attempt-no-account': 'Attempt',
};

const init = {
  data: 'landing',
  display: 'About Alleycat',
};

export const viewSlice = createSlice({
  name: 'view',
  initialState: init,
  reducers: {
    setView: (state, action) => {
      state.data = action.payload;
      state.display = mapping[action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setView } = viewSlice.actions;

export default viewSlice.reducer;
