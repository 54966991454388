import React, { useEffect, memo } from 'react'
import Api from '../../Api';
import { atptData } from '../../redux/attemptSlice';
import { useSelector, useDispatch } from 'react-redux';
import { isAtCheckpoint } from '../../helpers';
import MissionAttemptDashboard from '../mission/MissionAttemptDashboard';
import { setMap, mapAttemptCpts } from '../../redux/mapSlice';


const MissionDisplayMap = () => {

  const dispatch = useDispatch();
  const attempt = useSelector((state) => state.attempt);



  // MAP STUFF /////////////////////////////////////////////////

  const loc = useSelector((state) => state.map.loc);

  // Effect for setting the map
  useEffect(() => {
    dispatch(setMap({active: true, mapType: 'attempt'}));
  }, [dispatch]);

  // Effect for setting the mapAttemptCpts
  useEffect(() => {
    dispatch(mapAttemptCpts(attempt.data?.cpts));
  }, [dispatch, attempt.data?.cpts]);

  //////////////////////////////////////////////////////////////








  /* Effect for updating attempt state when checkpoint(s) are reached */
  useEffect(() => {
    async function updateAtpt(visitedIdxs) {
      const data = {
        attempt: attempt.data,
        visitedIdxs: visitedIdxs,
      };
      const res = await Api.POST(`/attempt/visit-checkpoint`, { data: data });
      dispatch(atptData(res.data));
    }

    if (!loc || !attempt.data) {
      return;
    }
    const visited = [];
    for (let i = 0; i < attempt.data.cpts.length; i++) {
      const cpt = attempt.data.cpts[i];
      if (!cpt.visited) {
        if (isAtCheckpoint(loc, cpt.gmapsLoc)) {
          visited.push(i);
        }
      }
    }
    if (visited.length > 0) {
      // async function to send visited idxs to backend
      updateAtpt(visited);
    }
  }, [loc, dispatch, attempt]);



  return (
    <div className="h-full w-full">
      <MissionAttemptDashboard />
    </div>
  );
};




export default memo(MissionDisplayMap);

