import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setMap } from '../../redux/mapSlice';
import {
  myMissionsStatus,
  myMissionsError,
  myMissions,
} from '../../redux/myMissionsSlice';
import { atptData, atptStatus } from '../../redux/attemptSlice';
import { setView } from '../../redux/viewSlice';
import { useGetAccessToken } from '../../hooks';
import Api from '../../Api';
import {
  MissionList,
} from './MissionListElement';
import { RefreshData } from '../reusable/Buttons';



export default function MyMissions() {
  const dispatch = useDispatch();
  const getAccessToken = useGetAccessToken();

  // Effect for setting the map
  useEffect(() => {
    dispatch(setMap({active: false, mapType: null}));
  }, [dispatch]);

  const mmData = useSelector((state) => state.myMissions.data);
  const mmStatus = useSelector((state) => state.myMissions.status);

  const atpt = useSelector((state) => state.attempt);
  
  const [selectedIdx, setSelectedIdx] = useState('');
  const [reqDeleteMission, setReqDeleteMission] = useState(false);

  // effect for getting mission list
  useEffect(() => {
    async function getData() {
      const token = await getAccessToken();
      const res = await Api.GET('/mission/list-mine', { token: token });
      if (res?.status === 200) {
        dispatch(myMissions(res?.data ?? []));
      } else {
        dispatch(myMissionsError(res?.status));
      }
    }
    if (mmStatus === 'requested') {
      dispatch(myMissionsStatus('done'));
      getData();
    }
  }, [getAccessToken, dispatch, mmData, mmStatus]);




  // effect for attempting a mission
  useEffect(() => {
    async function getData() {
      const token = await getAccessToken();
      const data = mmData[selectedIdx];
      const res = await Api.POST(`/attempt/new-attempt`, { token: token, data: data });
      dispatch(atptData(res.data));
      dispatch(atptStatus('done'));
    }

    if (atpt.status === 'requested') {
      getData();
      dispatch(setView('attempt'));
    }
  }, [dispatch, atpt, mmData, selectedIdx, getAccessToken]);




  useEffect(() => {
    async function getData() {
      const token = await getAccessToken();
      const missionName = mmData[selectedIdx].name;
      await Api.DELETE(`/mission/delete-saved?name=${missionName}`, { token: token });
      dispatch(myMissionsStatus('requested'));
    }
    if (reqDeleteMission) {
      setReqDeleteMission(false);
      getData();
    }
  }, [dispatch, selectedIdx, reqDeleteMission, getAccessToken, mmData]);




  function handleAttemptMission(idx) {
    setSelectedIdx(idx);
    dispatch(atptStatus('requested'));
  }

  function handleDeleteMission(idx) {
    setSelectedIdx(idx);
    setReqDeleteMission(true);
  }

  return (
    <div className="">
      <RefreshData onClick={() => dispatch(myMissionsStatus('requested'))} />
      <MissionList
        missionList={mmData}
        handleAttemptMission={handleAttemptMission}
        handleDeleteMission={handleDeleteMission}
      />
    </div>
  );
}
