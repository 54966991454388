import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    token: '',
    roles: [],
  },
  reducers: {

    setSession: (state, action) => {
      state.token = action.payload.token;
      state.roles = action.payload.roles;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSession } = sessionSlice.actions;

export default sessionSlice.reducer;
