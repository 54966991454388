import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from './sessionSlice';
import viewReducer from './viewSlice';
import profileReducer from './profileSlice';
import missionReducer from './missionSlice';
import attemptReducer from './attemptSlice';
import attemptNoAccountReducer from './attemptNoAccountSlice';
import myMissionsReducer from './myMissionsSlice';
import myAttemptsReducer from './myAttemptsSlice';
import searchedMissionsReducer from './searchedMissionsSlice';
import mapReducer from './mapSlice';

export default configureStore({
  reducer: {
    session: sessionReducer,
    view: viewReducer,
    profile: profileReducer,
    mission: missionReducer,
    attempt: attemptReducer,
    attemptNoAccount: attemptNoAccountReducer,
    myMissions: myMissionsReducer,
    myAttempts: myAttemptsReducer,
    searchedMissions: searchedMissionsReducer,
    map: mapReducer,
  },
});

