

export function DropDownInput({ options, value, setValue, label }) {

  return (
    <div className="flex justify-center">
      {label ? (
        <div className="flex justify-between">
          <label>{label}</label>
        </div>
      ) : (<></>)}
      <select
        className="bg-bgInput border-2 border-bdrMain"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        {options.map((option, idx) => <option key={idx} value={option.value}>{option.text}</option>)}
      </select>
    </div>
  );
}



export function MultiChoiceInput({options, chosen, setChosen, label}) {

  function choose(choice) {
    setChosen([...chosen, choice]);
  }

  function unchoose(choice) {
    let _chosen = [];
    for (let i = 0; i < chosen.length; i++) {
      if (choice !== chosen[i]) {
        _chosen.push(chosen[i]);
      }
    }
    setChosen(_chosen);
  }

  return (
    <div>
      {label ? (
        <div className="flex justify-between">
          <label>{label}</label>
        </div>
      ) : (<></>)}
      <div className="h-fit w-full flex flex-wrap justify-center">
        {options.map((option, idx) => {
          const isChosen = chosen.includes(option.tag);
          return (
            <button
              key={idx}
              className={isChosen ?
                "h-auto w-auto mx-[1em] mb-[.5em] py-[.12em] px-[.5em] bg-bgMultiButtonChosen text-txtNavHeader rounded-md" :
                "h-auto w-auto mx-[1em] mb-[.5em] py-[.12em] px-[.5em] bg-bgMultiButton text-txtNavHeader rounded-md"}
              onClick={isChosen ? () => unchoose(option.tag) : () => choose(option.tag)}
            >{option.tag}</button>
          );
        })}
      </div>
    </div>
  );
}







export function TextInput({value, setValue, label, placeholder}) {
  return (
    <div className="w-full flex justify-center">
      {label ? (
        <label className="w-auto mr-[.5em]">{label}</label>
      ) : (<></>)}
      <div>
        <input
          className="bg-bgInput text-txtMain border-2 border-bdrMain"
          type="text"
          required
          value={value}
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </div>
  );
}



export function PasswordInput({value, setValue, label, placeholder}) {
  return (
    <div className="w-full flex justify-center">
      {label ? (
        <label className="w-auto mr-[.5em]">{label}</label>
      ) : (<></>)}
      <div>
        <input
          className="bg-bgInput text-txtMain border-2 border-bdrMain"
          type="password"
          required
          value={value}
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </div>
  );
}





export function NumInput({value, setValue, label}) {
  return (
    <div className="w-full flex justify-center">
      <label className="w-auto mr-[.5em]">{label}</label>
      <div>
        <input
          value={value}
          className="bg-bgInput text-txtMain border-2 border-bdrMain"
          type="number"
          onChange={(e) => setValue(e.target.value)}/>
      </div>
    </div>
  );
}

