import { memo } from 'react';
import { Marker } from '@react-google-maps/api';
import png from '../../user-location.png';

const UserLocation = memo(({ loc }) => {

  const iconPin = {
    url: png,
    scaledSize: { width: 30, height: 30 },
  };

  return (
    <Marker
      position={loc}
      icon={iconPin}
    />
  );

});

export default UserLocation;

