


/* ========== cpt list state ========== */

export const initCptList = {
    cpts: null,
    status: 'done', // 'requested', 'received', 'done'
    error: 0,
  };

export function cptListReducer(state, action) {
  switch (action.type) {

    case 'set-status':
      return { ...state, status: action.payload };

    case 'set-error':
      return { ...state, error: action.payload };

    case 'set-cpts':
      return { ...state, cpts: action.payload };

    default:
      return state;
  }
}






/* ========== mission state ========== */

export function initMissionState() {
  let initMission = {
    cpts: null,
    status: 'done', // 'requested', 'received', 'done'
    error: 0,
  };
  const last = localStorage.getItem("mission");
  if (last) {
    initMission.cpts = JSON.parse(last);
  }
  return initMission;
}

export function missionReducer(state, action) {
  switch (action.type) {

    case 'set-status':
      return { ...state, status: action.payload };

    case 'set-error':
      return { ...state, error: action.payload };

    case 'set-cpts':
      localStorage.setItem("mission", JSON.stringify(action.payload));
      return { ...state, cpts: action.payload };

    case 'visited':
      let newCptArr = state.cpts;
      for (let i = 0; i < action.payload.length; i++) {
        newCptArr[action.payload[i]].visited = true;
      }
      localStorage.setItem("mission", JSON.stringify(newCptArr));
      return { ...state, cpts: newCptArr };

    default:
      return state;
  }
}











/* ========== mission request state ========== */
export const initMissionReq = {
  data: {
    coords: [null, null],
    tags: [],
    radius: 10,
    n: 3,
    cptSource: 'database',
  },
  isValid: false,
};

export function missionReqReducer(state, action) {
  switch (action.type) {

    case 'init':
      return {
        data: {
          coords: [null, null],
          tags: [],
          radius: 10,
          n: 3,
          cptSource: 'database',
        },
        isValid: false,
      };

    case 'set-cpt-source':
      return { ...state, data: { ...state.data, cptSource: action.payload } };

    case 'set-coords':
      return { ...state, data: { ...state.data, coords: [Number(action.payload?.lng),Number(action.payload?.lat)] } };

    case 'set-tags':
      return { ...state, data: { ...state.data, tags: action.payload } };

    case 'set-radius':
      let newRadius;
      if (action.payload === '') {
        newRadius = action.payload;
      } else {
        newRadius = Number(action.payload);
      }
      return { ...state, data: { ...state.data, radius: newRadius } };

    case 'set-n':
      let newN;
      if (action.payload === '') {
        newN = action.payload;
      } else {
        newN = Number(action.payload);
      }
      return { ...state, data: { ...state.data, n: newN } };

    case 'init-custom':
      return { ...state, data: { ...state.data, coords: [0, 0], n: 0, radius: 0, tags: [] } };

    default:
      return state;
  }
}











/* ========== new checkpoint state ========== */
export const initNewCpt = {
  data: { name: '', coords: [null, null], tags: [] },
  status: 'done', // 'requested', 'received', 'done'
  error: 0,
};

export function newCptReducer(state, action) {
  switch (action.type) {

    case 'init-data':
      const init = { name: '', coords: [null, null], tags: [] };
      return { ...state, data: init };

    case 'set-status':
      // 'requested', 'done'
      return { ...state, status: action.payload };

    case 'set-error':
      return { ...state, error: action.payload };

    case 'set-coords':
      let cptNewCoords = state.data;
      cptNewCoords.coords[0] = Number(action.payload?.lng);
      cptNewCoords.coords[1] = Number(action.payload?.lat);
      return { ...state, data: cptNewCoords };

    case 'set-name':
      let cptNewName = state.data;
      cptNewName.name = action.payload;
      return { ...state, data: cptNewName };

    case 'set-tags':
      let cptNewTags = state.data;
      cptNewTags.tags = action.payload;
      return { ...state, data: cptNewTags };

    default:
      return state;
  }
}





/* ========== new user state ========== */
export const initNewUsr = {
  data: { username: '', password: '' },
  status: 'done', // 'requested', 'received', 'done'
  error: 0,
};

export function newUsrReducer(state, action) {
  switch (action.type) {

    case 'init-data':
      const init = { username: '', password: '' };
      return { ...state, data: init };

    case 'set-status':
      return { ...state, status: action.payload };

    case 'set-error':
      return { ...state, error: action.payload };

    case 'set-username':
      let newUsername = state.data;
      newUsername.username = action.payload;
      return { ...state, data: newUsername };

    case 'set-password':
      let newPassword = state.data;
      newPassword.password = action.payload;
      return { ...state, data: newPassword };

    default:
      return state;
  }
}


