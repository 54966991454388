
function H2({text}) {
  return (
    <div>
      <h2 className="text-lg font-bold">{text}</h2>
    </div>
  );
}

export default H2;
