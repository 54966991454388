import React, { useState, useEffect, useCallback, memo } from 'react'
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useSelector, useDispatch } from 'react-redux';

import UserLocation from './UserLocation';
import { normalCptIcon, visitedCptIcon } from './Icons';
import { useLocation } from '../../hooks';
import { mapLoc, mapMarkerPosition, mapActiveMarker } from '../../redux/mapSlice';

const Map = () => {

  const dispatch = useDispatch();

  const mapType = useSelector((state) => state.map.mapType);
  const loc = useLocation();
  const [center, setCenter] = useState(null);
  const [snap, setSnap] = useState(true);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
  });

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setSnap(true);
  }, [])

  const onUnmount = useCallback(function callback(map) {
  }, [])

  useEffect(() => {
    if (snap && isLoaded && loc) {
      setCenter(loc);
      setSnap(false);
    }
  }, [loc, setCenter, snap, setSnap, isLoaded]);

  const onMapClick = useCallback((event) => {
    const selected = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    dispatch(mapMarkerPosition(selected));
  }, [dispatch]);



  // Effect for updating loc in redux
  useEffect(() => {
    dispatch(mapLoc(loc));
  }, [loc, dispatch]);


  

  /* ATTEMPT STUFF */
  const attemptCpts = useSelector((state) => state.map.attemptCpts);

  /* SELECT STUFF */
  const selectCpts = useSelector((state) => state.map.selectCpts);
  const markerPosition = useSelector((state) => state.map.markerPosition);
  const activeMarker = useSelector((state) => state.map.activeMarker);



  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    dispatch(mapActiveMarker(marker));
  };


  return (
    <>
      {isLoaded ? (
        <div className="h-full w-full relative">
          <GoogleMap
            mapContainerStyle={{width: '100%', height: '100%'}}
            zoom={6}
            center={center}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={onMapClick}
          >

            <UserLocation loc={loc} />


            {/* ATTEMPT STUFF */}
            {mapType === 'attempt' ?
              (
                <>{
                  (attemptCpts ?? []).map(({name, gmapsLoc, visited}, idx) => {
                    if (visited) {
                      return (
                        <Marker
                          key={idx}
                          position={gmapsLoc}
                          icon={visitedCptIcon}
                          onClick={() => handleActiveMarker(idx)}
                        >
                          {activeMarker === idx ? (
                            <InfoWindow onCloseClick={() => dispatch(mapActiveMarker(null))}>
                              <div className="text-black">{name}</div>
                            </InfoWindow>
                          ) : (<></>)}
                        </Marker>
                      );
                    }
                    return (
                      <Marker
                        key={idx}
                        position={gmapsLoc}
                        icon={normalCptIcon}
                        onClick={() => handleActiveMarker(idx)}
                      >
                        {activeMarker === idx ? (
                          <InfoWindow onCloseClick={() => dispatch(mapActiveMarker(null))}>
                            <div className="text-black">{name}</div>
                          </InfoWindow>
                        ) : (<></>)}
                      </Marker>
                    );
                  })
                }</>
              ) : (<></>)
            }




            {/* SELECT STUFF */}
            {(mapType === 'select' && markerPosition) &&
                <Marker position={markerPosition} icon={normalCptIcon} />
            }
            {mapType === 'select' ?
              (
                <>{
                  (selectCpts ?? []).map(({name, gmapsLoc, visited}, idx) => {

                    return (
                      <Marker
                        key={idx}
                        position={gmapsLoc}
                        icon={visitedCptIcon}
                        onClick={() => handleActiveMarker(idx)}
                      >
                        {activeMarker === idx ? (
                          <InfoWindow onCloseClick={() => dispatch(mapActiveMarker(null))}>
                            <div className="text-black">{name}</div>
                          </InfoWindow>
                        ) : (<></>)}
                      </Marker>
                    );

                  })
                }</>
              ) : (<></>)
            }



          </GoogleMap>
        </div>
      ) : (
        <></>
      )}
    </>
  )
};

export default memo(Map);

