import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { useLogout } from '../hooks';
import Landing from './Landing';
import CreateMission from './mission/CreateMission';
import CreateMissionNoAccount from './no-account/CreateMission';
import MyMissions from './mission/MyMissions';
import MyAttempts from './mission/MyAttempts';
import SearchMissions from './mission/SearchMissions';
import Map from './maps/Map';
import SearchMissionsNoAccount from './no-account/SearchMissions';
import AttemptMission from './mission/AttemptMission';
import AttemptMissionNoAccount from './no-account/AttemptMission';
import Profile from './Profile';
import CreateCheckpoint from './admin-tools/CreateCheckpoint';
import ShowCheckpoints from './admin-tools/ShowCheckpoints';
import H1 from './reusable/H1';
import { setView } from '../redux/viewSlice';

function NotLoggedIn() {
  const {
    isAuthenticated,
    loginWithRedirect,
    user,
  } = useAuth0();

  const dispatch = useDispatch();

  const logout = useLogout();

  const view = useSelector((state) => state.view.data);
  const viewName = useSelector((state) => state.view.display);

  const [isDropped, setIsDropped] = useState(false);

  const options = useMemo(() => {

    let options = {};
    options.Missions = [];
    options.Attempts = [];

    if (isAuthenticated) {
      options.Checkpoints = [];
    }

    if (isAuthenticated && user?.user_roles?.includes('su')) {
      options.Checkpoints.push({action: () => dispatch(setView('show-cpts')), label: 'Show Checkpoints'});
    }

    if (isAuthenticated) {

      options.Checkpoints.push({action: () => dispatch(setView('create-cpt')), label: 'Create Checkpoint'});

      options.Missions.push({action: () => dispatch(setView('create-mission')), label: 'Create Mission'});
      options.Missions.push({action: () => dispatch(setView('my-missions')), label: 'My Missions'});
      options.Missions.push({action: () => dispatch(setView('search-missions')), label: 'Search Missions'});

      options.Attempts.push({action: () => dispatch(setView('my-attempts')), label: 'My Attempts'});

    }

    if (!isAuthenticated) {

      options.Account = [
        {action: loginWithRedirect, label: 'Login'},
      ];

      options.Missions.push({action: () => dispatch(setView('create-mission-no-account')), label: 'Create Mission'});
      options.Missions.push({action: () => dispatch(setView('search-missions-no-account')), label: 'Search Missions'});

      options.Attempts.push({action: () => dispatch(setView('attempt-no-account')), label: 'Current Attempt'});

    } else {
      options.Account = [
        //{action: () => dispatch(setView('profile')), label: 'Profile'},
        {action: () => logout(), label: 'Logout'},
      ];
    }


    return options;
  }, [logout, dispatch, isAuthenticated, loginWithRedirect, user]);


  useEffect(() => {
    if (isAuthenticated) {
      //dispatch(setView('profile'));
      dispatch(setView('landing'));
    }
  }, [isAuthenticated, dispatch]);


  /*
    * map stuff
    */
  const mapState = useSelector((state) => state.map);

  return (
    <div className="h-full w-full bg-bgVoid">



      <div className="h-[5%] w-full bg-bgNavHeader border-b-2 border-white">

        <div className="h-full max-w-[50em] flex justify-around mx-auto">

          <div className="h-full w-fit">
            <button
              onClick={() => {dispatch(setView('landing')); setIsDropped(false);}}
              className="bg-bgNavHeader text-left text-txtNavHeader text-xl font-bold h-full"
            >Alleycat</button>
          </div>

          <div className="h-full w-fit">
            <div
              onClick={() => setIsDropped(!isDropped)}
              className="h-full w-full flex flex-col justify-center"
            >
              <button
                className="w-[2em] h-[2em] bg-menu bg-contain"
              >
              </button>
            </div>
          </div>

        </div>

      </div>






      <div className="h-[95%] bg-bgMain">


        <div className="h-full relative">

          {isDropped ? (
            <div className="h-full w-full z-50 absolute bg-bgNavHeader">
              <div className="h-full max-w-[50em] mx-auto flex flex-wrap justify-end">

                <div className="h-full w-full">
                  {Object.keys(options).map((group, gid) => {
                    return (
                      <div className="w-full text-txtNavHeader px-[2em] py-[1em] text-left" key={gid}>
                        <h1 className="text-2xl italic">{group}</h1>
                        {options[group].map(({action, label}, oid) => {
                          return (
                            <div key={oid} >
                              <button
                                key={oid}
                                className="hover:underline"
                                onClick={() => {action(); setIsDropped(false)}}
                              >{label}</button>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>

              </div>
            </div>
          ) : (<></>)}


          <div className="h-full max-w-[50em] mx-auto">


            <div className='h-[6%] text-center flex flex-col justify-around'>
              <div className=''>
                <H1 text={viewName} />
              </div>
            </div>

            <div className={mapState.active ? 'h-[40%]' : 'h-0'}>
              <Map />
            </div>




            <div className={mapState.active ? 'h-[54%] overflow-y-auto' : 'h-[94%] overflow-y-auto'}>
              {view === 'landing' ? (
                <Landing />
              ) : ( <></> )}
              {view === 'profile' ? (
                <Profile />
              ) : ( <></> )}
              {view === 'create-mission' ? (
                <CreateMission />
              ) : ( <></> )}
              {view === 'create-mission-no-account' ? (
                <CreateMissionNoAccount />
              ) : ( <></> )}
              {view === 'my-missions' ? (
                <MyMissions />
              ) : ( <></> )}
              {view === 'search-missions' ? (
                <SearchMissions />
              ) : ( <></> )}
              {view === 'search-missions-no-account' ? (
                <SearchMissionsNoAccount />
              ) : ( <></> )}
              {view === 'attempt' ? (
                <AttemptMission />
              ) : ( <></> )}
              {view === 'attempt-no-account' ? (
                <AttemptMissionNoAccount />
              ) : ( <></> )}
              {view === 'my-attempts' ? (
                <MyAttempts />
              ) : ( <></> )}
              {view === 'create-cpt' ? (
                <CreateCheckpoint />
              ) : ( <></> )}
              {view === 'show-cpts' ? (
                <ShowCheckpoints />
              ) : ( <></> )}
            </div>

          </div>

        </div>

      </div>
    </div>
  );
}

export default NotLoggedIn;
