import { createSlice } from '@reduxjs/toolkit';

const init = {
  status: 'requested',
  error: 0,
  data: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: init,
  reducers: {
    profStatus: (state, action) => {
      state.status = action.payload;
    },
    profError: (state, action) => {
      state.error = action.payload;
    },
    profData: (state, action) => {
      state.data = action.payload;
    },
    resetProf: (state) => {
      state.status = init.status;
      state.error = init.error;
      state.data = init.data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { profStatus, profError, profData, resetProf } = profileSlice.actions;

export default profileSlice.reducer;
