import { createSlice } from '@reduxjs/toolkit';



const initialState = {
  mapType: null,
  active: false,

  // both modes
  activeMarker: null,
  loc: null,

  // attempt mode
  //handleActiveMarker: (idx) => null,
  //setActiveMarker: () => null,
  attemptCpts: null,

  // select mode
  selectCpts: null,
  markerPosition: null,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState: initialState,
  reducers: {
    setMap: (state, action) => {
      state.mapType = action.payload.mapType;
      state.active = action.payload.active;

      state.activeMarker = initialState.activeMarker;
      state.loc = initialState.loc;

      state.attemptCpts = initialState.attemptCpts;

      state.selectCpts = initialState.selectCpts;
      state.markerPosition = initialState.markerPosition;
    },
    mapActiveMarker: (state, action) => {
      state.activeMarker = action.payload;
    },
    mapLoc: (state, action) => {
      state.loc = action.payload;
    },
    mapAttemptCpts: (state, action) => {
      state.attemptCpts = action.payload;
    },
    mapSelectCpts: (state, action) => {
      state.selectCpts = action.payload;
    },
    mapMarkerPosition: (state, action) => {
      state.markerPosition = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMap,
  mapActiveMarker,
  mapLoc,
  mapAttemptCpts,
  mapSelectCpts,
  mapMarkerPosition,
} = mapSlice.actions;

export default mapSlice.reducer;
