import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  searchedMissionsStatus,
  searchedMissionsError,
  searchedMissions,
} from '../../redux/searchedMissionsSlice';
import {
  setMap,
} from '../../redux/mapSlice';
import { atptData, atptStatus } from '../../redux/attemptNoAccountSlice';
import { setView } from '../../redux/viewSlice';
import { TextInput, DropDownInput } from '../reusable/Input';
import { YesButton } from '../reusable/Buttons';
import Api from '../../Api';
import {
  MissionList,
} from '../mission/MissionListElement';



export default function MyMissions() {
  const dispatch = useDispatch();

  const smData = useSelector((state) => state.searchedMissions.data);
  const smStatus = useSelector((state) => state.searchedMissions.status);

  const atpt = useSelector((state) => state.attemptNoAccount);
  
  const [selectedIdx, setSelectedIdx] = useState(0);

  const [searchMode, setSearchMode] = useState('location'); // name | location
  const [searchName, setSearchName] = useState('');

  const searchModeOptions = [
    { value: 'name', text: 'Search by name' },
    { value: 'location', text: 'Search by location' },
  ];




  // MAP STUFF /////////////////////////////////////////////////

  const markerPosition = useSelector((state) => state.map.markerPosition);

  // Effect for setting the map
  useEffect(() => {
    if (searchMode === 'location') {
      dispatch(setMap({active: true, mapType: 'select'}));
    } else if (searchMode === 'name') {
      dispatch(setMap({active: false, mapType: 'select'}));
    }
  }, [dispatch, searchMode]);

  //////////////////////////////////////////////////////////////




  // effect for getting mission list by name
  useEffect(() => {
    async function getData() {
      const res = await Api.GET(`/mission/list-saved?name=${searchName}`);
      if (res?.status === 200) {
        dispatch(searchedMissions(res?.data ?? []));
      } else {
        dispatch(searchedMissionsError(res?.status));
      }
    }
    if (smStatus === 'requested' && searchName !== '' && searchMode === 'name') {
      dispatch(searchedMissionsStatus('done'));
      getData();
    }
  }, [dispatch, searchName, smData, smStatus, searchMode]);


  // effect for getting mission list by location
  useEffect(() => {
    async function getData() {
      const res = await Api.GET(`/mission/list-saved-by-location?lon=${markerPosition.lng}&lat=${markerPosition.lat}`);
      if (res?.status === 200) {
        dispatch(searchedMissions(res?.data ?? []));
      } else {
        dispatch(searchedMissionsError(res?.status));
      }
    }
    if (smStatus === 'requested' && searchMode === 'location' && markerPosition) {
      dispatch(searchedMissionsStatus('done'));
      getData();
    }
  }, [dispatch, searchMode, markerPosition, smData, smStatus]);



  // effect for attempting a mission
  useEffect(() => {
    async function getData() {
      const data = smData[selectedIdx];
      const res = await Api.POST(`/attempt/new-attempt-no-account`, { data: data });
      dispatch(atptData(res.data));
    }

    if (atpt.status === 'requested') {
      dispatch(atptStatus('done'));
      getData();
      dispatch(setView('attempt-no-account'));
    }
  }, [dispatch, atpt, selectedIdx, smData]);

  function handleAttemptMission(idx) {
    setSelectedIdx(idx);
    dispatch(atptStatus('requested'));
  }

  function handleSearchMission(idx) {
    setSelectedIdx(idx);
    dispatch(searchedMissionsStatus('requested'));
  }

  return (
    <div className="">

      <div className="bg-bgMain p-[1em]">
        <DropDownInput options={searchModeOptions} value={searchMode} setValue={setSearchMode} />
        <div className={searchMode === 'name' ? "flex justify-center m-[.5em]" : "h-0 overflow-hidden"} >
          <TextInput placeholder="Mission name" value={searchName} setValue={(_name) => setSearchName(_name)} />
        </div>
        <div className="mt-[.5em]">
          <YesButton
            onClick={handleSearchMission}
            text='Search'
            isActive={(searchMode === 'name' && searchName !== '') || (searchMode === 'location' && markerPosition && markerPosition)}
          />
        </div>
      </div>

      <MissionList
        missionList={smData}
        handleAttemptMission={handleAttemptMission}
      />
    </div>
  );
}
