import { createSlice } from '@reduxjs/toolkit';


const init = {
  data: [],
  status: 'done', // 'requested', 'received', 'done'
  error: 0,
};


export const searchedMissionsSlice = createSlice({
  name: 'searchedMissions',
  initialState: init,
  reducers: {
    searchedMissionsStatus: (state, action) => {
      state.status = action.payload;
    },
    searchedMissionsError: (state, action) => {
      state.status = action.payload;
    },
    searchedMissions: (state, action) => {
      state.data = action.payload;
    },
    resetSearchedMissions: (state) => {
      state.data = init.data;
      state.status = init.status;
      state.error = init.error;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  searchedMissionsStatus,
  searchedMissionsError,
  searchedMissions,
  resetSearchedMissions,
} = searchedMissionsSlice.actions;

export default searchedMissionsSlice.reducer;

