import { useMemo } from 'react';
import axios from 'axios';

import { useLogout } from './hooks';
import NotLoggedIn from './components/NotLoggedIn';


function App() {

  const logout = useLogout();

  function genQueryString(params) {
    if (params.length === 0)
      return '';

    let str = '?';
    for (let i = 0; i < params.length; i++) {
      const param = params[i];
      str.concat(`${param.key}=${param.value}`);
    }
    str = str.substring(0, str.length - 1);
    return str;
  }

  /* Networking */
  useMemo(() => {
    axios.interceptors.request.use(
      (req) => {

        const options = req?.options ?? {};
        const reqToken = options?.token;
        const data = options?.data;
        const params = options?.params;

        if (reqToken) {
          req.headers['Authorization'] = 'Bearer ' + reqToken;
        }
        if ((data ?? false) !== false) {
          req.data = data;
        }
        if ((params ?? false) !== false) {
          const qString = genQueryString(params);
          req.url = req.url.concat(qString);
        }

        return req;
      },
      (err) => {
        return;
      }
    )
  }, []);

  useMemo(() => {
    axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        /*
        if (err?.code === "ERR_NETWORK") {
          alert('Network error');
        */
        const status = err?.response?.status;
        if (status === 470) {
          alert('Session expired, please log in');
          logout();
        }
        return { status: status };
      }
    )
  }, [logout]);

  return (
    <div className="h-screen w-screen font-main text-txtMain border-bdrMain">
      <NotLoggedIn />
    </div>
  );
}

export default App;
