import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import H2 from '../reusable/H2';

export default function MissionAttemptDashboard() {
  const { isAuthenticated } = useAuth0();

  const attempt = useSelector((state) => state.attempt);
  const attemptNoAccount = useSelector((state) => state.attemptNoAccount);

  const start = attempt?.data?.start ?
    (new Date(attempt?.data?.start)).toLocaleString() : '';

  const startNoAccount = attemptNoAccount?.data?.start ?
    (new Date(attemptNoAccount?.data?.start)).toLocaleString() : '';





  if (isAuthenticated) {
    return (
      <div>

        <div className="bg-bg2nd my-[1em] mx-[1em] p-[1em] rounded-lg border-l-2 border-b-2 border-white">
          <div>
            <H2 text={attempt?.data?.missionName ?? 'Unnamed'} />
          </div>
          <div>
            <table className="text-left">
              <tbody>
                <tr>
                  <th className="pr-[.5em]">Start</th>
                  <td>{start}</td>
                </tr>
                <tr>
                  <th className="pr-[.5em]">Total checkpoints</th>
                  <td>{attempt?.data?.cpts?.length}</td>
                </tr>
                <tr>
                  <th className="pr-[.5em]">Visited checkpoints</th>
                  <td>{attempt?.data?.cptsVisited}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="bg-bg2nd my-[1em] mx-[1em] p-[1em] rounded-lg border-l-2 border-b-2 border-white">
          <div>
            <H2 text="Remaining Checkpoints" />
          </div>
          <div>
            {attempt?.data?.cpts?.map((cpt, idx) => {
              return (
                <div key={idx}>
                  {!cpt.visited && cpt.name}
                </div>
              );
            })}
          </div>
        </div>

      </div>
    );
  }


  return (
    <div>

      <div className="bg-bg2nd my-[1em] mx-[1em] p-[1em] rounded-lg border-l-2 border-b-2 border-white">
        <div>
          <H2 text={'Current Attempt'} />
        </div>
        <div>
          <table className="text-left">
            <tbody>
              <tr>
                <th className="pr-[.5em]">Start</th>
                <td>{startNoAccount}</td>
              </tr>
              <tr>
                <th className="pr-[.5em]">Total checkpoints</th>
                <td>{attemptNoAccount?.data?.cpts?.length}</td>
              </tr>
              <tr>
                <th className="pr-[.5em]">Visited checkpoints</th>
                <td>{attemptNoAccount?.data?.cptsVisited}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="bg-bg2nd my-[1em] mx-[1em] p-[1em] rounded-lg border-l-2 border-b-2 border-white">
        <div>
          <H2 text="Remaining Checkpoints" />
        </div>
        <div>
          {attemptNoAccount?.data?.cpts?.map((cpt, idx) => {
            return (
              <div key={idx}>
                {!cpt.visited && cpt.name}
              </div>
            );
          })}
        </div>
      </div>

    </div>
  );


}
