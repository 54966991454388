

export function newAttempt(mission) {
  const start = Date.now();
  const end = -1;
  const missionName = '';
  const missionOwner = '';
  const missionOwnerId = '';
  const attemptee = '';
  const attempteeId = '';
  const cptsVisited = 0;
  const cpts = mission.cpts;

  return {
    start,
    end,
    missionName,
    missionOwner,
    missionOwnerId,
    attemptee,
    attempteeId,
    cptsVisited,
    cpts,
  };
}



export function updateAttempt(attempt, visitedIdxs) {

  // set visited to true for the checkpoints
  for (let i = 0; i < visitedIdxs.length; i++) {
    attempt.cpts[visitedIdxs[i]].visited = true;
  }

  // update the number of checkpoints visited
  let cptsVisited = 0;
  for (let i = 0; i < attempt.cpts.length; i++) {
    if (attempt.cpts[i].visited) {
      cptsVisited ++;
    }
  }
  attempt.cptsVisited = cptsVisited;

  // update the attempt end time
  let end = -1;
  if (cptsVisited === attempt.cpts.length) {
    end = Date.now();
    attempt.end = end;
  }

  // return the updated mission to the client
  return attempt;
}
