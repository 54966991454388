/* test */
import { createSlice } from '@reduxjs/toolkit';


function initState() {
  let initAttempt = {
    data: null,
    status: 'done', // 'requested', 'received', 'done'
    error: 0,
  };
  return initAttempt;
}


export const attemptSlice = createSlice({
  name: 'mission',
  initialState: initState(),
  reducers: {
    atptStatus: (state, action) => {
      state.status = action.payload;
    },
    atptError: (state, action) => {
      state.error = action.payload;
    },
    atptData: (state, action) => {
      state.data = action.payload;
    },
    atptClearData: (state) => {
      state.data = initState();
    },
  },
});

// Action creators are generated for each case reducer function
export const { atptStatus, atptError, atptData, atptClearData } = attemptSlice.actions;

export default attemptSlice.reducer;

