
function H1({text}) {
  return (
    <div>
      <h1 className="text-2xl font-bold font-header">{text}</h1>
    </div>
  );
}

export default H1;
