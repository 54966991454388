import React, { useState, useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { NumInput } from '../reusable/Input';
import { YesButton, NoButton } from '../reusable/Buttons';
import Api from '../../Api';
import { initCptList, cptListReducer } from '../../state';
import { useGetAccessToken } from '../../hooks';
import {
  setMap,
  mapSelectCpts,
} from '../../redux/mapSlice';

const CreateCheckpoint = () => {
  const dispatch = useDispatch();

  const getAccessToken = useGetAccessToken();

  const [cptList, cptListDispatch] = useReducer(cptListReducer, initCptList);
  const [radius, setRadius] = useState(0);


  // MAP STUFF /////////////////////////////////////////////////

  const markerPosition = useSelector((state) => state.map.markerPosition);

  // Effect for setting the map
  useEffect(() => {
    dispatch(setMap({active: true, mapType: 'select'}));
  }, [dispatch]);

  // Effect for setting display cpts
  useEffect(() => {
    dispatch(mapSelectCpts(cptList.cpts));
  }, [dispatch, cptList.cpts]);

  //////////////////////////////////////////////////////////////











  useEffect(() => {
    async function getCpts() {
      const token = await getAccessToken()
      const coords = [Number(markerPosition.lng), Number(markerPosition.lat)];
      const res = await Api.POST('/checkpoint/all-within', {token: token, data: {coords: coords, radius: radius}});
      if (res?.status === 200) {
        let cpts = res?.data ?? [];
        for (let i = 0; i < cpts.length; i++) {
          cpts[i].visited = false;
          cpts[i].gmapsLoc = {
            lng: cpts[i].location.coordinates[0],
            lat: cpts[i].location.coordinates[1],
          };
        }
        cptListDispatch({type: 'set-cpts', payload: cpts});
      } else {
        cptListDispatch({type: 'set-error', payload: res?.status});
      }
      cptListDispatch({type: 'set-status', payload: 'done'});
    }
    if (cptList.status === 'requested') {
      getCpts();
    }
  }, [radius, cptList, cptListDispatch, markerPosition, getAccessToken]);







  useEffect(() => {
    cptListDispatch({type: 'set-cpts', payload: null});
    cptListDispatch({ type: 'set-coords', payload: markerPosition });
  }, [markerPosition]);










  return (
    <div className="">

      <div className="relative">
        {!markerPosition ? <div className="absolute w-full h-full bg-[#ffffff]/50"></div> : <></>}
        {cptList.cpts ? 
          <NoButton
            text='Clear'
            onClick={() => cptListDispatch({type: 'set-cpts', payload: null})}
            isActive={markerPosition}
          />
          :
          <YesButton
            text='Search'
            onClick={() => cptListDispatch({type: 'set-status', payload: 'requested'})}
            isActive={markerPosition}
          />
        }
      </div>

      <div className="bg-bgMain my-[2em] p-[1em]">
        <NumInput value={radius} setValue={setRadius} label='Radius' />
      </div>

    </div>
  );
};

export default CreateCheckpoint;

