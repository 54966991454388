import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useGetAccessToken } from '../../hooks';
import { setMap } from '../../redux/mapSlice';
import {
  myAttemptsStatus,
  myAttemptsError,
  myAttemptsData,
} from '../../redux/myAttemptsSlice';
import { atptData, atptStatus } from '../../redux/attemptSlice';
import { setView } from '../../redux/viewSlice';
import { YesButton, NoButton } from '../reusable/Buttons';
import Api from '../../Api';
import { RefreshData } from '../reusable/Buttons';





export function AttemptsList({ list, handleResumeAttempt, handleDeleteAttempt}) {

  const dispatch = useDispatch();

  // Effect for setting the map
  useEffect(() => {
    dispatch(setMap({active: false, mapType: null}));
  }, [dispatch]);

  return (

    <>
      {
        list.map((attempt, idx) => {


          const startDT = (new Date(attempt.start)).toLocaleString();
          //const startDT = (new Date(attempt.start)).toUTCString();

          const endDT = attempt.end === -1 ? '' :
            (new Date(attempt.end)).toLocaleString();

          return (
            <div key={idx} className="bg-bg2nd my-[1em] mx-[1em] p-[1em] rounded-lg border-l-2 border-b-2 border-white">

              <div className="text-xl font-bold">
                {attempt.missionName}
              </div>

              <div className="">


                <div className="">
                  <table className="text-left">
                    <tbody>
                      <tr>
                        <th className="pr-[.5em]">Mission Owner:</th>
                        <td>{attempt.missionOwner}</td>
                      </tr>
                      <tr>
                        <th className="pr-[.5em]">Start:</th>
                        <td>{startDT}</td>
                      </tr>
                      <tr>
                        <th className="pr-[.5em]">End:</th>
                        <td>{endDT}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="flex flex-row justify-around">
                  <div className="h-full w-full">
                    <NoButton
                      onClick={() => handleDeleteAttempt(idx)}
                      text='Delete'
                      isActive={handleDeleteAttempt}
                    />
                  </div>
                  <div className="h-full w-full">
                    <YesButton
                      onClick={() => handleResumeAttempt(idx)}
                      text='Resume'
                      isActive={attempt.end === -1}
                    />
                  </div>
                </div>



              </div>

            </div>
          );
        })
      }
    </>

  );
}









export default function MyAttempts() {
  const dispatch = useDispatch();
  const getAccessToken = useGetAccessToken();

  const myAttempts = useSelector((state) => state.myAttempts);

  const atpt = useSelector((state) => state.attempt);
  
  const [selectedIdx, setSelectedIdx] = useState('');
  const [reqDelete, setReqDelete] = useState(false);


  // effect for getting attempt list
  useEffect(() => {
    async function getData() {
      const token = await getAccessToken();
      const res = await Api.GET('/attempt/list-mine', { token: token });
      if (res?.status === 200) {
        dispatch(myAttemptsData(res?.data ?? []));
      } else {
        dispatch(myAttemptsError(res?.status));
      }
    }
    if (myAttempts.status === 'requested') {
      dispatch(myAttemptsStatus('done'));
      getData();
    }
  }, [getAccessToken, dispatch, myAttempts]);




  // effect for attempting an attempt
  useEffect(() => {
    async function getData() {
      const token = await getAccessToken();
      const data = myAttempts.data[selectedIdx];
      const res = await Api.POST(`/attempt/get-one-attempt`, { token: token, data: data });
      dispatch(atptData(res.data));
    }

    if (atpt.status === 'requested') {
      dispatch(atptStatus('done'));
      getData();
      dispatch(setView('attempt'));
    }
  }, [dispatch, atpt, myAttempts, selectedIdx, getAccessToken]);

  
  // effect for deleting attempt
  useEffect(() => {
    async function getData() {
      const token = await getAccessToken();
      const data = myAttempts.data[selectedIdx];
      await Api.DELETE(`/attempt/delete`, { token: token, data: data });
      dispatch(myAttemptsStatus('requested'));
    }
    if (reqDelete) {
      getData();
      setReqDelete(false);
    }
  }, [dispatch, selectedIdx, reqDelete, getAccessToken, myAttempts]);



  function handleResumeAttempt(idx) {
    setSelectedIdx(idx);
    dispatch(atptStatus('requested'));
  }

  function handleDeleteAttempt(idx) {
    setSelectedIdx(idx);
    setReqDelete(true);
  }


  return (
    <div className="">
      <RefreshData onClick={() => dispatch(myAttemptsStatus('requested'))} />
      <AttemptsList
        list={myAttempts.data}
        handleResumeAttempt={handleResumeAttempt}
        handleDeleteAttempt={handleDeleteAttempt}
      />
    </div>
  );
}
