import { createSlice } from '@reduxjs/toolkit';


const init = {
  data: [],
  status: 'requested', // 'requested', 'received', 'done'
  error: 0,
};


export const myAttemptsSlice = createSlice({
  name: 'myAttempts',
  initialState: init,
  reducers: {
    myAttemptsStatus: (state, action) => {
      state.status = action.payload;
    },
    myAttemptsError: (state, action) => {
      state.status = action.payload;
    },
    myAttemptsData: (state, action) => {
      state.data = action.payload;
    },
    myAttemptsReset: (state) => {
      state.data = init.data;
      state.status = init.status;
      state.error = init.error;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  myAttemptsStatus,
  myAttemptsError,
  myAttemptsData,
  myAttemptsReset,
} = myAttemptsSlice.actions;

export default myAttemptsSlice.reducer;

