import { createSlice } from '@reduxjs/toolkit';

import { calculateMissionCenter } from '../helpers';


const initMission = {
  name: '',
  owner: '',
  conf: null,
  cpts: [],
  status: 'done', // 'requested', 'received', 'done'
  error: 0,
};


export const missionSlice = createSlice({
  name: 'mission',
  initialState: initMission,
  reducers: {
    mishInit: (state) => {
      state.conf = null;
      state.cpts = [];
      state.owner = '';
      state.name = '';
    },
    mishStatus: (state, action) => {
      state.status = action.payload;
    },
    mishError: (state, action) => {
      state.error = action.payload;
    },
    mishCpts: (state, action) => {
      state.cpts = action.payload;
    },
    mishName: (state, action) => {
      state.name = action.payload;
    },
    mishConf: (state, action) => {
      // get conf in correct format
      const conf = {
        cptSource: action.payload.cptSource,
        location: {
          type: 'Point',
          coordinates: action.payload.coords,
        },
        tags: action.payload.tags,
        radius: action.payload.radius,
        n: action.payload.n,
      };
      state.conf = conf;
    },
    mishCoords: (state) => {
      const center = calculateMissionCenter(state.cpts);
      state.conf.location.coordinates = center;
    },
    mishCreateMission: (state, action) => {
      // get conf in correct format
      const conf = {
        cptSource: action.payload.conf.cptSource,
        location: {
          type: 'Point',
          coordinates: action.payload.conf.coords,
        },
        tags: action.payload.conf.tags,
        radius: action.payload.conf.radius,
        n: action.payload.conf.n,
      };
      state.conf = conf;
      state.cpts = action.payload.cpts;
    },
    mishLoadMission: (state, action) => {
      state.conf = action.payload.conf;
      state.cpts = action.payload.cpts;
      state.owner = action.payload.owner;
      state.name = action.payload.name;
    },
    mishAddCustomCpt: (state, action) => {
      state.conf.n = state.cpts.length + 1;
      state.cpts.push(action.payload);
    },
    mishDeleteCustomCpt: (state, action) => {
      const newArr = [];
      for (let i = 0; i < state.cpts.length; i++) {
        if (i !== action.payload) {
          newArr.push(state.cpts[i]);
        }
      }
      state.conf.n = newArr.length;
      state.cpts = newArr;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  mishStatus,
  mishError,
  mishCpts,
  mishName,
  mishConf,
  mishCoords,
  mishCreateMission,
  mishLoadMission,
  mishInit,
  mishAddCustomCpt,
  mishDeleteCustomCpt,
} = missionSlice.actions;

export default missionSlice.reducer;

