import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

import { setMap } from '../redux/mapSlice';
import H1 from './reusable/H1';

function Profile() {
  const dispatch = useDispatch();
  const { user } = useAuth0();

  // Effect for setting the map
  useEffect(() => {
    dispatch(setMap({active: false, mapType: null}));
  }, [dispatch]);

  return (
    <div className="h-full w-full">
      <div className="text-center py-[1em]">
        <H1 text={user?.username} />
      </div>
    </div>
  );
}

export default Profile;
