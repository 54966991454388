import { createSlice } from '@reduxjs/toolkit';


const init = {
  data: [],
  status: 'requested', // 'requested', 'received', 'done'
  error: 0,
};


export const myMissionsSlice = createSlice({
  name: 'myMissions',
  initialState: init,
  reducers: {
    myMissionsStatus: (state, action) => {
      state.status = action.payload;
    },
    myMissionsError: (state, action) => {
      state.status = action.payload;
    },
    myMissions: (state, action) => {
      state.data = action.payload;
    },
    resetMyMissions: (state) => {
      state.data = init.data;
      state.status = init.status;
      state.error = init.error;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  myMissionsStatus,
  myMissionsError,
  myMissions,
  resetMyMissions,
} = myMissionsSlice.actions;

export default myMissionsSlice.reducer;

