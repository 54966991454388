import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const Api = {
  GET: async (route, options) => {
    const res = await axios({
      method: 'get',
      url: `${API_URL}${route}`,
      options: options
    });
    console.log(res);
    return res;
  },
  POST: async (route, options) => {
    const res = await axios({
      method: 'post',
      url: `${API_URL}${route}`,
      options: options
    });
    console.log(res);
    return res;
  },
  DELETE: async (route, options) => {
    const res = await axios({
      method: 'delete',
      url: `${API_URL}${route}`,
      options: options
    });
    console.log(res);
    return res;
  },
};


export default Api;
