
export function RefreshData({onClick}) {
  return (
    <button className="h-[2em] w-[2em] bg-refresh bg-contain"
      onClick={onClick}
    >
    </button>
  );
}

export function YesButton({text, onClick, isActive}) {

  return (
      <button
        onClick={isActive ? onClick : null}
        className={
          isActive ? "h-full w-full bg-bgYesButton hover:bg-bgYesButtonHov" :
          "h-full w-full bg-bgButtonInactive"
        }
      >
        {text}
      </button>
  );
}

export function NoButton({text, onClick, isActive}) {

  return (
      <button
        onClick={isActive ? onClick : null}
        className={
          isActive ? "h-full w-full bg-bgNoButton hover:bg-bgNoButtonHov":
          "h-full w-full bg-bgButtonInactive"
        }
      >
        {text}
      </button>
  );
}

