import { YesButton, NoButton } from '../reusable/Buttons';

export function MissionList({ missionList, handleAttemptMission, handleDeleteMission }) {
  return (

    <div>
      {
        missionList.map((mission, idx) => {
          return (
            <div key={idx} className="bg-bg2nd my-[1em] mx-[1em] p-[1em] rounded-lg border-l-2 border-b-2 border-white">

              <div className="text-xl font-bold">
                {mission.name}
              </div>

              <div className="">


                <div className="">
                  <table className="text-left">
                    <tbody>
                      <tr>
                        <th className="pr-[.5em]">User:</th>
                        <td>{mission.owner}</td>
                      </tr>
                      <tr>
                        <th className="pr-[.5em]">Source:</th>
                        <td>{mission.conf.cptSource}</td>
                      </tr>
                      <tr>
                        <th className="pr-[.5em]">Checkpoints:</th>
                        <td>{mission.conf.n}</td>
                      </tr>
                      <tr>
                        <th className="pr-[.5em]">Radius:</th>
                        <td>{mission.conf.radius} km</td>
                      </tr>
                    </tbody>
                  </table>
                </div>


                <div className="flex flex-row justify-around">
                  <div className="h-full w-full">
                    <NoButton
                      onClick={() => handleDeleteMission(idx)}
                      text='Delete'
                      isActive={handleDeleteMission}
                    />
                  </div>
                  <div className="h-full w-full">
                    <YesButton
                      onClick={() => handleAttemptMission(idx)}
                      text='Attempt'
                      isActive={true}
                    />
                  </div>
                </div>


              </div>

            </div>
          );
        })
      }
    </div>

  );
}

