
import AttemptMissionUnordered from './AttemptMissionUnordered';

function AttemptMission() {

  return (
    <div className="">
      <AttemptMissionUnordered />
    </div>
  );
}

export default AttemptMission;

