import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { setView } from '../redux/viewSlice';
import { resetProf } from '../redux/profileSlice';
import { resetMyMissions } from '../redux/myMissionsSlice';
import { resetSearchedMissions } from '../redux/searchedMissionsSlice';


export function useLocation2() {
  /* This hook waits for the user to move to update their location */

  const [loc, setLoc] = useState(null);
  const handlerId = useRef(null);

  useEffect(() => {
    const options = {
      maximumAge: 0,
      timeout: 5000,
      enableHighAccuracy: false,
    };
    function watchLocation() {
      if (navigator.geolocation) {
        const id = navigator.geolocation.watchPosition((position) => {
          setLoc({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        }, (err) => {
          console.log(err);
        }, options);
        handlerId.current = id;
      } else {
        console.log("Browser does not support geolocation");
      }
    };
    watchLocation();
    return function clearWatchHandler() {
      navigator.geolocation.clearWatch(handlerId.current);
    }
  }, [setLoc]);

  return loc;
}





export function useLocation() {
  /* This hook updates the user's location every 2 seconds */

  const [loc, setLoc] = useState({lat: 0, lng: 0});

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const l = {            // ADDED
          lat: position.coords.latitude,  // ADDED
          lng: position.coords.longitude, // ADDED
        };
        console.log(l);
        setLoc(l);
      });
    } else {
      console.log("Browser does not support geolocation");
    }
  };

  setTimeout(() => {
    getUserLocation();
    clearTimeout();
  }, 2000);

  return loc;
}






export function useLocationTest() {
  /* This hook is for testing location functionality */

  const [loc, setLoc] = useState({lat: 51.5, lng: - 0.010});

  useEffect(() => {
    setTimeout(() => {
      setLoc({lat: loc.lat, lng: loc.lng + 0.005});
      clearTimeout();
    }, 2000);
  }, [loc, setLoc]);

  return loc;
}




export function useLogout() {
  const dispatch = useDispatch();
  const {
    logout,
  } = useAuth0();
  const logoutFunc = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    dispatch(resetProf());
    dispatch(resetMyMissions());
    dispatch(resetSearchedMissions());
    dispatch(setView('landing'));
  }, [dispatch, logout]);
  return logoutFunc;
}





export function useGetAccessToken() {
  const {
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();

  async function getAccessToken() {
    const authParams = {
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      //scope: 'read:root',
    };

    let token;

    try {
      token = await getAccessTokenSilently({
        authorizationParams: authParams,
      });
    } catch (err) {
      token = await getAccessTokenWithPopup({
        authorizationParams: authParams,
      });
    }
    console.log(token);
    return token;
  }

  return getAccessToken;
}



